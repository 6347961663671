import React, { useEffect, useState, useContext } from "react"
import { Flex } from "rebass"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"

import InputField from "../input"
import Button from "../button"
import Select from "../select"

const SignupForm = ({ onSignup, initValues, disabledFields, hiddenFields }) => {
  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  })

  useEffect(() => {
    for (const [key, value] of Object.entries(initValues)) {
      setValue(key, value)
    }
  }, [initValues])

  return (
    <form onSubmit={handleSubmit(onSignup)}>
      <InputField
        disabled={disabledFields.includes("name")}
        mb={3}
        label="Account name"
        name="name"
        placeholder="Acme Inc."
        ref={register}
        boldLabel={true}
      />
      <InputField
        disabled={disabledFields.includes("email")}
        mb={3}
        label="Email"
        placeholder="lebron@james.com"
        name="email"
        ref={register}
        boldLabel={true}
      />
      <Flex justifyContent="space-between" mb={3}>
        <InputField
          disabled={disabledFields.includes("first_name")}
          mr={2}
          label="First Name"
          placeholder="Lebron"
          boldLabel={true}
          name="first_name"
          ref={register}
        />
        <InputField
          disabled={disabledFields.includes("last_name")}
          label="Last Name"
          placeholder="James"
          boldLabel={true}
          name="last_name"
          ref={register}
        />
      </Flex>
      {!hiddenFields.includes("password") && (
        <InputField
          placeholder="SuperSecret123!"
          label="Password"
          type="password"
          name="password"
          ref={register}
          boldLabel={true}
          mb={3}
        />
      )}
      <Select
        disabled={disabledFields.includes("role")}
        mb={3}
        label="What is your role"
        name="role"
        ref={register}
        boldLabel={true}
        options={[{ value: "Software Developer" }]}
        placeholder="Select your role"
        selectStyle={{ padding: "8px", flex: "100% 0 0 !important" }}
      />
      <Select
        disabled={disabledFields.includes("industry")}
        label="What your company does"
        name="industry"
        ref={register}
        boldLabel={true}
        options={[{ value: "Software Development" }]}
        placeholder="Select your industry"
        selectStyle={{ padding: "8px", flex: "100% 0 0 !important" }}
      />
      <Button type="submit" variant={"cta"} mt={4} width={1}>
        Confirm & Create Account
      </Button>
    </form>
  )
}

SignupForm.defaultProps = {
  initValues: {},
  disabledFields: [],
  hiddenFields: [],
}

SignupForm.propTypes = {
  onSignup: PropTypes.func.isRequired,
  initValues: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.string,
  }),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
}

export default SignupForm
